<template>
  <section
    style="padding-left: 25px;
  margin-left: 20px; padding-right: 10px;
  margin-right: 10px;"
  >
    <v-row>
      <v-col cols="12" sm="12" md="6">
        <v-row>
          <v-col cols="12" sm="12" md="3">
            <v-btn @click="addCard = !addCard" class="info  ml-6 mr-2 mt-4 ">
              <v-icon left> {{ icons.mdiDatabasePlusOutline }} </v-icon>
              Ajouter Heure de début de récitation
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="addCard">
      <v-col cols="12" sm="12" md="12">
        <v-card class="mb-6 ml-6 mr-2 mt-1">
          <v-form ref="form" lazy-validation>
            <v-card-text class="mt-4">
              <v-row>
                <v-col cols="8" sm="8" md="8">
                  <v-autocomplete
                    :return-object="true"
                    clearable
                    v-model="selectedVerset"
                    :items="listVersetBySurate"
                    item-text="french_text"
                    label="Select a Verse"
                    variant="outlined"
                    @change="getListSousversetByVersetId"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <div v-for="(chunk, index) in textChunks" :key="index">
                <v-row>
                  <v-col cols="8" sm="2" md="8">
                    <v-text-field
                      outlined
                      v-model="chunk.sousVersetText"
                      :label="`Part ${chunk.part} `"
                      :rules="rules(chunk.sousVersetText)"
                      :readonly="chunk.edit"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" sm="2" md="2">
                    <vue-timepicker
                      manual-input
                      hide-clear-button
                      style=" margin-top:18px "
                      v-model="chunk.sousVersetStartTimeInSec"
                      format="HH:mm:ss"
                      label="Select a Verse"
                    ></vue-timepicker>
                  </v-col>
                </v-row>
              </div>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <div class="d-flex flex-row-reverse"></div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <div class="d-flex flex-row-reverse" style="margin-right:10%">
                  <v-btn
                    :disabled="selectedVerset.length === 0"
                    class="info"
                    @click="dialogAddVersetStartTime = true"
                  >
                    Ajouter
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <v-card class="mb-6 ml-6 mt-6 mr-2">
      <v-card-title>Liste Verset </v-card-title>
      <v-text-field
        style="padding:15px"
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        clearable
        :search="search"
      ></v-text-field>
      <v-data-table
        :search="search"
        :footer-props="{
          'items-per-page-options': [5, 10, 15, 30],
        }"
        :headers="headersVerset"
        :items="ListVersetTime"
        class="elevation-1"
        item-key="id"
      >
        <template v-slot:item.startTimeInSec="{ item }">
          {{ formatTime(item.startTimeInSec) }}
        </template>
        <template v-slot:item.modifier="{ item }">
          <v-icon @click="redirectToEditVersetTime(item)">
            {{ icons.mdiPencil }}
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <v-row justify="center">
      <v-dialog v-model="dialogDeleteVersetStartTime" max-width="600">
        <v-card>
          <v-card-title class="text-h6">
            Etes vous sur de vouloir Verset depuis le Recitateur ?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error darken-1 error"
              text
              @click="dialogDeleteVersetStartTime = false"
            >
              Annuler
            </v-btn>

            <v-btn
              color="info darken-1 "
              text
              @click="RemoveVersetStartTimeToRecitateur()"
            >
              Confirmer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialogAddVersetStartTime" max-width="600">
        <v-card>
          <v-card-title class="text-h6">
            Etes vous sur de vouloir ajouter Verset a Recitateur ?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error darken-1 error"
              text
              @click="dialogAddVersetStartTime = false"
            >
              Annuler
            </v-btn>
            <v-btn color="info darken-1 " text @click="processVerseDataArray()">
              Confirmer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </section>
</template>
<script>
import { maxLength } from "vuelidate/lib/validators";
import {
  mdiPlus,
  mdiDeleteOutline,
  mdiPencil,
  mdiDatabasePlusOutline,
} from "@mdi/js";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import axios from "axios";
import Constant from "@/utils/constants";
import { getAllVersetBySurate } from "@/views/qorani/services/services";
export default {
  name: "VersetStartTime",
  components: { VueTimepicker },
  data() {
    return {
      search: "",
      VersetStartTimeApi: [
        {
          versetId: null,
          recitateurId: null,
          sousVersetStartTimeInSec: null,
          sousVersetText: "",
          part: 0,
        },
      ],
      maxCharsPerChunk: 100,
      maxWordLength: 3,
      textChunks: [],
      dialogAddVersetStartTime: false,
      formattedTime: null,
      sousVersetStartTimeInSec: "00:00:00",
      selectedVerset: [],
      listVersetBySurate: [],
      addCard: false,
      icons: {
        mdiPlus,
        mdiDeleteOutline,
        mdiPencil,
        mdiDatabasePlusOutline,
      },
      VersetStartTime: {
        versetId: null,
        recitateurId: null,
        versetStartTimeInSec: null,
      },
      dialogDeleteVersetStartTime: false,
      ListVersetTime: [],
      headersVerset: [
        { text: "Verset en Arab", value: "arabic_text" },
        { text: "Verset en Français", value: "french_text" },
        { text: "Start Time", value: "startTimeInSec" },
        { text: "Modifier", value: "modifier", align: "center" },
      ],
    };
  },
  async beforeRouteEnter(to, from, next) {
    const suratId = to.params.suratId;
    const recitateurId = to.params.recitateurId;
    try {
      const response = await axios.get(
        `${Constant.QORANI}verset/getVersetForSurat/${suratId}/AndRecitateur/${recitateurId}`
      );
      next((vm) => {
        vm.ListVersetTime = response.data;
      });
    } catch (error) {
      console.error("Error fetching product details:", error);
      next(null);
    }
  },
  watch: {},
  methods: {
    redirectToEditVersetTime(item) {
      this.$router.push({
        name: "EditVersetStartTime",
        params: {
          versetId: item.versetId,
          recitateurId: this.$route.params.recitateurId,
        },
      });
    },
    rules(chunk) {
      return [(value) => maxLength(110)(value) || "Maximum 110 characters"];
    },
    formatTimeSegment(segment) {
      return segment < 10 ? `0${segment}` : segment;
    },
    selectedTimeFormatted(seconde) {
      const hours = Math.floor(seconde / 3600);
      const minutes = Math.floor((seconde % 3600) / 60);
      const seconds = this.seconde % 60;
      return `${this.formatTimeSegment(hours)}:${this.formatTimeSegment(
        minutes
      )}:${this.formatTimeSegment(seconds)}`;
    },
    chunkText(sousVersetText, chunkSize, versetId) {
      if (
        typeof sousVersetText !== "string" ||
        typeof chunkSize !== "number" ||
        chunkSize <= 0
      ) {
        throw new Error("Invalid input parameters.");
      }
      const words = sousVersetText.split(/\s+/);
      const chunks = [];
      let chunk = [];
      let wordCount = 0;
      let charCount = 0;
      let part = 1;
      for (const word of words) {
        if (word.length > this.maxWordLength) {
          wordCount++;
        }
        if (charCount + word.length + chunk.length > this.maxCharsPerChunk) {
          chunks.push({
            part: part,
            sousVersetText: chunk.join(" "),
            sousVersetStartTimeInSec: "00:00:00",
            recitateurId: this.$route.params.recitateurId,
            versetId: versetId,
          });
          chunk = [word];
          wordCount = word.length > this.maxWordLength ? 1 : 0;
          charCount = word.length;
          part++;
        } else {
          chunk.push(word);
          charCount += word.length;
        }
        if (wordCount >= chunkSize) {
          chunks.push({
            part: part,
            sousVersetText: chunk.join(" "),
            sousVersetStartTimeInSec: "00:00:00",
            recitateurId: this.$route.params.recitateurId,
            versetId: versetId,
          });
          chunk = [];
          wordCount = 0;
          charCount = 0;
          part++;
        }
      }

      if (chunk.length > 0) {
        chunks.push({
          part: part,
          sousVersetText: chunk.join(" "),
          sousVersetStartTimeInSec: "00:00:00",
          recitateurId: this.$route.params.recitateurId,
          versetId: versetId,
        });
      }

      return chunks;
    },
    async getListSousversetByVersetId() {
      const response = await fetch(
        Constant.QORANI +
          `sousVerset/getListSousversetByVersetId/${this.selectedVerset.versetId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      try {
        const data = await response.json();
        if (data.length > 0) {
          this.textChunks = data.map((chunk) => ({
            ...chunk,
            edit: true,
            sousVersetStartTimeInSec: "00:00:00",
          }));
        }
        if (data.length === 0) {
          this.textChunks = this.chunkText(
            this.selectedVerset.french_text,
            6,
            this.selectedVerset.versetId
          );
        }
      } catch (error) {
        alert(
          "Une erreur est survenue lors de la suppression du juz, veuillez réessayez plus tard, Merci."
        );
      }
    },
    async processVerseDataArray() {
      for (let i = 0; i < this.textChunks.length; i++) {
        const verseData = this.textChunks[i];
        const updatedSeconds = this.selectedTimeInSeconds(
          verseData.sousVersetStartTimeInSec
        );
        verseData.recitateurId = Number(this.$route.params.recitateurId);
        verseData.sousVersetStartTimeInSec = updatedSeconds;
        delete verseData.edit;
        this.textChunks[i] = verseData;
      }
      await this.addVersetStartTimeToRecitateur(this.textChunks);
    },
    async addVersetStartTimeToRecitateur(verseData) {
      const apiUrl =
        Constant.QORANI + "recitateur/addVersetStartTimeToRecitateur";
      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(verseData),
        });
        if (response.ok) {
          window.location.reload();
        }
        if (!response.ok) {
          throw new Error("API request failed");
        }
      } catch (error) {
        console.error("API request error:", error);
      }
    },
    selectedTimeInSeconds(time) {
      if (!time) return 0;
      const timeParts = time.split(":");
      const hours = Number(timeParts[0]);
      const minutes = Number(timeParts[1]);
      const seconds = Number(timeParts[2]);
      return hours * 3600 + minutes * 60 + seconds;
    },
    formatTime(seconds) {
      if (seconds < 60) {
        return `${seconds} secondes`;
      } else if (seconds < 3600) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes} minutes ${remainingSeconds} secondes`;
      } else {
        const hours = Math.floor(seconds / 3600);
        const remainingMinutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;
        return `${hours} heures ${remainingMinutes} minutes ${remainingSeconds} secondes`;
      }
    },
  },
  async created() {
    this.listVersetBySurate = await getAllVersetBySurate(
      this.$route.params.suratId
    );
  },
  computed: {},
};
</script>
